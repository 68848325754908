<template>
    <modal ref="modalCrearCalendar" titulo="Crear calendario" adicional="Crear" @adicional="add">
        <ValidationObserver ref="createCalendar">
            <div class="row m-3 f-15">
                <div class="col-12">
                    <p class="input-label-top">Nombre</p>
                    <ValidationProvider v-slot="{errors}" name="nombre" rules="required">
                        <el-input v-model="generalCalendar.nombre" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {  mapActions } from 'vuex'
import { GeneralCalendarFactory } from '~/domain/entities/configurar/obras/calendario_general/factories/GeneralCalendarFactory'
export default {
    data(){
        return {
            generalCalendar: GeneralCalendarFactory.instantiateEmpty() 
        }
    },
    methods: {
        ...mapActions({
            crearCalendarioGeneral: 'configurar/obras/calendario_general/Action_create_calendario_general',
        }),
        toggle(){
            this.$refs.createCalendar.reset()
            this.$refs.modalCrearCalendar.toggle()
        },
        async add(){
            const valid = await this.$refs.createCalendar.validate()
            if (valid) {
                await this.crearCalendarioGeneral({
                    nombre: this.generalCalendar.nombre
                })
                this.$refs.modalCrearCalendar.toggle()
            }
            //this.$emit('createGeneralCalendar', this.generalCalendar)
            this.generalCalendar = GeneralCalendarFactory.instantiateEmpty() 
        }
    }
}
</script>